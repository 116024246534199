import { NavLink } from "react-router-dom";
import aboutBanner01 from "../../assets/img/PHOTO_001.jpg";
import aboutBanner02 from "../../assets/img/PHOTO_002.jpg";
import aboutBanner03 from "../../assets/img/PHOTO_003.jpg";
import aboutBanner04 from "../../assets/img/PHOTO_004.jpg";
import aboutBanner05 from "../../assets/img/PHOTO_005.jpg";


function AboutPage(){
    return (
    <>
    <div class="UT7-body-area">
        <div class="container-fluid">      

            <div class="UT7-gallery-body-section">

                <div class="UT7-gallery-cards-area">
                    <div class="row justify-content-center">

                        {/* <div class="col-xxl-12">
                            <h3 class="UT7-gallery-page-title text-center mb-5">About</h3>
                        </div> */}
                        <div class="col-xxl-12">
                            <div id="carouselExampleIndicators" class="carousel slide UT7-about-banner-slider" data-bs-ride="carousel">
                                <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                </div>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <div class="UT7-about-sldr-img-frame"><img src={aboutBanner01} class="UT7-about-sldr-img" /></div>
                                    </div>
                                    <div class="carousel-item">
                                    <div class="UT7-about-sldr-img-frame"><img src={aboutBanner02} class="UT7-about-sldr-img" /></div>
                                    </div>
                                    <div class="carousel-item">
                                    <div class="UT7-about-sldr-img-frame"><img src={aboutBanner03} class="UT7-about-sldr-img" /></div>
                                    </div>
                                    <div class="carousel-item">
                                    <div class="UT7-about-sldr-img-frame"><img src={aboutBanner04} class="UT7-about-sldr-img" /></div>
                                    </div>
                                    <div class="carousel-item">
                                    <div class="UT7-about-sldr-img-frame"><img src={aboutBanner05} class="UT7-about-sldr-img" /></div>
                                    </div>
                                </div>
                                {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button> */}
                            </div>
                            <div class="col-xxl-12">
                                <p>Welcome to the prestigious UltraTech 7 Wonders of Durga Puja, a groundbreaking initiative that celebrates the fusion of engineering brilliance and architectural marvels within the heart of Bengal's grandest cultural festival, Durga Puja. As the pioneering platform of its kind in Bengal, we bring together the world of artistry and engineering expertise to honor and recognize the most exceptional pandal structures and architectural wonders crafted by the creative minds behind Durga Puja pandals.</p>
                            </div>
                            <div class="col-xxl-12">
                                <h3 class="UT7-section-hdng mb-2"><b>Our Vision</b></h3>
                                <p>Our vision is to elevate the art of Durga Puja pandal creation to new heights by spotlighting the technical ingenuity and architectural brilliance that go into designing and constructing these awe-inspiring structures. We aspire to foster a culture of innovation and excellence within the Durga Puja community by acknowledging and rewarding the dedication and craftsmanship that breathe life into the Puja pandals.</p>
                            </div>
                            <div class="col-xxl-12">
                                <h3 class="UT7-section-hdng mb-2"><b>The Unprecedented Concept</b></h3>
                                <p>UltraTech 7 Wonders of Durga Puja stands as the first-ever initiative in Bengal to have engineers and architects as esteemed judges for Durga Puja pandal evaluation. This groundbreaking concept acknowledges that the creation of a magnificent pandal is not just an artistic endeavor but also an engineering marvel, and it brings together two seemingly distinct worlds under one roof.</p>
                            </div>
                            <div class="col-xxl-12">
                                <h3 class="UT7-section-hdng mb-2"><b>The Judging Panel</b></h3>
                                <p>Our judging panel comprises a select group of highly accomplished engineers and architects who bring their technical expertise and keen aesthetic sense to the evaluation process. They assess each pandal based on a set of rigorous criteria that include structural integrity, architectural innovation, aesthetic appeal, and sustainability.</p>
                            </div>
                            <div class="col-xxl-12">
                                <h3 class="UT7-section-hdng mb-2"><b>Recognizing Excellence</b></h3>
                                <p>We believe in celebrating and rewarding excellence. The UltraTech 7 Wonders of Durga Puja awards are bestowed upon pandals that excel in various categories, ranging from traditional to contemporary designs. These awards not only recognize the efforts of the artisans but also encourage them to push the boundaries of creativity and craftsmanship.</p>
                            </div>
                            <div class="col-xxl-12">
                                <h3 class="UT7-section-hdng mb-2"><b>Join Us in Celebrating Excellence</b></h3>
                                <p>UltraTech 7 Wonders of Durga Puja invites you to join us in celebrating the convergence of tradition and innovation, artistry and engineering, and to witness the dazzling creations that emerge from the heart of Bengal's Durga Puja celebrations. Together, we will continue to push the boundaries of what is possible in pandal design and construction, ensuring that this beloved festival remains a beacon of cultural significance and creativity for generations to come.</p>
                            </div>




                            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Launch demo modal
                            </button> */}

                            


                        </div> 
                    </div>
                </div>

            </div>

        </div>
    </div>
                        
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>)
}




export default AboutPage;