import "./mediabtn.css"

export default function MediaButton() {
  return (
    <div className="float" title="Upload Media">
        <a className="noLinkBtn" href="https://forms.gle/nbVHRRGLuiybUPAT8" target="_blank">
        <span className="small-txt">
            Add Media 
        {/* <br/> */}
        <i class="fa-solid fa-upload my-float"></i>
        </span>
        </a>
      
        
    </div>
  )
}
