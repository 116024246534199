import { Outlet } from "react-router-dom"
import HeaderComponent from "../components/header.component/header"
import FooterComponent from "../components/footer.component/footer"



export default function RootLayout() {
  return (
    <>
    <div className="root-layout">
    {/* <!-- header start --> */}
    <HeaderComponent/>
    {/* <!-- header ended --> */}

    {/* main-content start */}
        <Outlet/>
    {/* main-content end */}

    {/* <!-- footer css start --> */}
    <FooterComponent/>
    {/* <!-- footer css ended --> */}
    </div>
    <div className="bg-static">
   
    </div>
    </>
  )
}
