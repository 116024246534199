import { useEffect, useRef, useState } from "react"
import { enviromentConfigs } from "../../env/enviroment-staging";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../components/loader/loader";

export default function Table() {
    const [pandalData,setPandaData] = useState([]);
    const [selectedState,setState] = useState('select');
    const [selectedCity,setCity] = useState('select');
    const [districts, setDistricts] = useState([]);
    const [uniqueID,setUniqueID] = useState(false);
    const [clickedOnDownload, setClick] = useState(false);
    const [showSpinner,setShowSpinner] = useState(false)
    const data = [
        {
            "pandal_amenities": [],
            "_id": "64e20f78772f593343a65cf0",
            "pandalId": "1me07653qa",
            "name_of_pandal": "Marvelous Pandal",
            "address_of_pandal": "78/2 Road Address Mars Avenue",
            "president_of_pandal": "Tony Stark",
            "president_phoneno": "123-456-7890",
            "secretary_of_pandal": "Pepper Potts",
            "secretary_phoneno": "987-654-3210",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "New York",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Bruce Banner",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "bruce@example.com",
            "contact_two_name": "Natasha Romanoff",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "natasha@example.com",
            "pandal_theme": "Superheroes of the Universe",
            "pandal_description": "A pandal celebrating iconic superheroes from various universes.",
            "pandal_architech": "Stark Industries",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                "Steel, glass, eco-friendly materials"
            ],
            "echofriendly_pandal": "true",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_options": [
                "Interactive exhibits",
                "Live performances"
            ],
            "pandal_files_link": "https://example.com/pandal-files",
            "pandal_of_ZONE": "KOL",
            "pandal_of_topHundred_KOL": false,
            "pandal_of_topThirty_KOL": false,
            "pandal_of_topEight_KOL": false,
            "pandal_of_topSeven_KOL": false,
            "pandal_of_Final_ROB": false,
            "pandal_of_Zonal_ROB": false,
            "pandal_of_topSeven_ROB": false,
            "pandal_of_Twentyfive_TRI": false,
            "pandal_of_topSeven_TRI": false,
            "pandal_has_wildcard": false,
            "createdAt": "2023-08-20T13:04:56.724Z",
            "updatedAt": "2023-08-20T13:04:56.724Z",
            "__v": 0
        },
        {
            "pandal_amenities": [],
            "_id": "64e20ff3d589e41253a27b0d",
            "pandalId": "1me07653qa2",
            "name_of_pandal": "Marvelous Pandal",
            "address_of_pandal": "78/2 Road Address Mars Avenue",
            "president_of_pandal": "Tony Stark",
            "president_phoneno": "123-456-7890",
            "secretary_of_pandal": "Pepper Potts",
            "secretary_phoneno": "987-654-3210",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "New York",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Bruce Banner",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "bruce@example.com",
            "contact_two_name": "Natasha Romanoff",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "natasha@example.com",
            "pandal_theme": "Superheroes of the Universe",
            "pandal_description": "A pandal celebrating iconic superheroes from various universes.",
            "pandal_architech": "Stark Industries",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                "Steel, glass, eco-friendly materials"
            ],
            "echofriendly_pandal": "true",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_options": [
                "Interactive exhibits",
                "Live performances"
            ],
            "pandal_files_link": "https://example.com/pandal-files",
            "pandal_of_ZONE": "KOL",
            "pandal_of_topHundred_KOL": false,
            "pandal_of_topThirty_KOL": false,
            "pandal_of_topEight_KOL": false,
            "pandal_of_topSeven_KOL": false,
            "pandal_of_Final_ROB": false,
            "pandal_of_Zonal_ROB": false,
            "pandal_of_topSeven_ROB": false,
            "pandal_of_Twentyfive_TRI": false,
            "pandal_of_topSeven_TRI": false,
            "pandal_has_wildcard": false,
            "createdAt": "2023-08-20T13:06:59.845Z",
            "updatedAt": "2023-08-20T13:06:59.845Z",
            "__v": 0
        },
        {
            "pandal_amenities": [],
            "_id": "64e20ff9d589e41253a27b0f",
            "pandalId": "1me07653qa3",
            "name_of_pandal": "Marvelous Pandal",
            "address_of_pandal": "78/2 Road Address Mars Avenue",
            "president_of_pandal": "Tony Stark",
            "president_phoneno": "123-456-7890",
            "secretary_of_pandal": "Pepper Potts",
            "secretary_phoneno": "987-654-3210",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "New York",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Bruce Banner",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "bruce@example.com",
            "contact_two_name": "Natasha Romanoff",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "natasha@example.com",
            "pandal_theme": "Superheroes of the Universe",
            "pandal_description": "A pandal celebrating iconic superheroes from various universes.",
            "pandal_architech": "Stark Industries",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                "Steel, glass, eco-friendly materials"
            ],
            "echofriendly_pandal": "true",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_options": [
                "Interactive exhibits",
                "Live performances"
            ],
            "pandal_files_link": "https://example.com/pandal-files",
            "pandal_of_ZONE": "KOL",
            "pandal_of_topHundred_KOL": false,
            "pandal_of_topThirty_KOL": false,
            "pandal_of_topEight_KOL": false,
            "pandal_of_topSeven_KOL": false,
            "pandal_of_Final_ROB": false,
            "pandal_of_Zonal_ROB": false,
            "pandal_of_topSeven_ROB": false,
            "pandal_of_Twentyfive_TRI": false,
            "pandal_of_topSeven_TRI": false,
            "pandal_has_wildcard": false,
            "createdAt": "2023-08-20T13:07:05.385Z",
            "updatedAt": "2023-08-20T13:07:05.385Z",
            "__v": 0
        },
        {
            "pandal_amenities": [],
            "_id": "64e20ffed589e41253a27b11",
            "pandalId": "1me07653qa4",
            "name_of_pandal": "Marvelous Pandal",
            "address_of_pandal": "78/2 Road Address Mars Avenue",
            "president_of_pandal": "Tony Stark",
            "president_phoneno": "123-456-7890",
            "secretary_of_pandal": "Pepper Potts",
            "secretary_phoneno": "987-654-3210",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "New York",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Bruce Banner",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "bruce@example.com",
            "contact_two_name": "Natasha Romanoff",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "natasha@example.com",
            "pandal_theme": "Superheroes of the Universe",
            "pandal_description": "A pandal celebrating iconic superheroes from various universes.",
            "pandal_architech": "Stark Industries",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                "Steel, glass, eco-friendly materials"
            ],
            "echofriendly_pandal": "true",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_options": [
                "Interactive exhibits",
                "Live performances"
            ],
            "pandal_files_link": "https://example.com/pandal-files",
            "pandal_of_ZONE": "KOL",
            "pandal_of_topHundred_KOL": false,
            "pandal_of_topThirty_KOL": false,
            "pandal_of_topEight_KOL": false,
            "pandal_of_topSeven_KOL": false,
            "pandal_of_Final_ROB": false,
            "pandal_of_Zonal_ROB": false,
            "pandal_of_topSeven_ROB": false,
            "pandal_of_Twentyfive_TRI": false,
            "pandal_of_topSeven_TRI": false,
            "pandal_has_wildcard": false,
            "createdAt": "2023-08-20T13:07:10.783Z",
            "updatedAt": "2023-08-20T13:07:10.783Z",
            "__v": 0
        },
        {
            "_id": "64ee5ee6ce20ba23d6d233c3",
            "name_of_pandal": "1Demo",
            "address_of_pandal": "Mars address",
            "president_of_pandal": "Jorge Bush",
            "president_phoneno": "212323232",
            "secretary_of_pandal": "Pepper Potts",
            "secretary_phoneno": "2232323232",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "select",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Bruce Banner",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "bruce@example.com",
            "contact_two_name": "Natasha Romanoff",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "natasha@example.com",
            "pandal_theme": "Superheroes of the Universe",
            "pandal_description": "A pandal celebrating iconic superheroes from various universes.",
            "pandal_architech": "Stark Industries",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": true,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": true,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "asdasd",
            "echofriendly_pandal": "yes",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": true,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": true,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": false,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "asdasd",
            "pandal_facebook_link": "https://app.cloudjiffy.com/",
            "pandal_insta_link": " ",
            "pandal_pincode": "700077",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "0efogr7ll",
            "createdAt": "2023-08-29T21:11:02.917Z",
            "updatedAt": "2023-08-29T21:11:02.917Z",
            "__v": 0
        },
        {
            "_id": "64fa2daf778001710d46164f",
            "name_of_pandal": "New Pandal Name",
            "address_of_pandal": "Some Real Address Here",
            "president_of_pandal": "Rony Roy",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Arko Dey",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "North 24 Parganas",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Riju Dutta",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Smriti Dey",
            "contact_two_phone": "34324",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "Theme name",
            "pandal_description": "A pandal celebrating durga puja.",
            "pandal_architech": "Architech name",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": true,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": true,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "asdasd",
            "echofriendly_pandal": "yes",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": true,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": true,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": true,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": false,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "asdasd",
            "pandal_facebook_link": "asdasd",
            "pandal_insta_link": "",
            "pandal_pincode": "700886",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "bmkpodq75",
            "createdAt": "2023-09-07T20:08:15.676Z",
            "updatedAt": "2023-09-07T20:08:15.676Z",
            "__v": 0
        },
        {
            "_id": "64fa30f1778001710d461651",
            "name_of_pandal": "New Pandal Name",
            "address_of_pandal": "Some Real Address Here",
            "president_of_pandal": "Rony Roy",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Arko Dey",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "Paschim Bardhaman",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Riju Dutta",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Smriti Dey",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "Theme name",
            "pandal_description": "A pandal celebrating durga puja.",
            "pandal_architech": "Architech name",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "asdasdas",
            "echofriendly_pandal": "yes",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": false,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "asdasdasd",
            "pandal_facebook_link": "asdasd",
            "pandal_insta_link": "",
            "pandal_pincode": "70010",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "porbrb6mtb",
            "createdAt": "2023-09-07T20:22:09.309Z",
            "updatedAt": "2023-09-07T20:22:09.309Z",
            "__v": 0
        },
        {
            "_id": "64fa313c778001710d461653",
            "name_of_pandal": "New Pandal Name",
            "address_of_pandal": "Some Real Address Here",
            "president_of_pandal": "Rony Roy",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Arko Dey",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "Kolkata",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Riju Dutta",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Smriti Dey",
            "contact_two_phone": "23123",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "Theme name",
            "pandal_description": "A pandal celebrating durga puja.",
            "pandal_architech": "Architech name",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": true,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "asdasdas",
            "echofriendly_pandal": "no",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": true,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": true,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": true,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": false,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "asdasd",
            "pandal_facebook_link": "asdasd",
            "pandal_insta_link": "",
            "pandal_pincode": "7005",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "bqmb5tn45",
            "createdAt": "2023-09-07T20:23:24.818Z",
            "updatedAt": "2023-09-07T20:23:24.818Z",
            "__v": 0
        },
        {
            "_id": "64fa31a6778001710d461655",
            "name_of_pandal": "New Pandal Name",
            "address_of_pandal": "Some Real Address Here",
            "president_of_pandal": "Rony Roy",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Arko Dey",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "North 24 Parganas",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Riju Dutta",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Smriti Dey",
            "contact_two_phone": "234",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "Theme name",
            "pandal_description": "A pandal celebrating durga puja.",
            "pandal_architech": "Architech name",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": true,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": true,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": true,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "asdasdasd",
            "echofriendly_pandal": "no",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": true,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": true,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": false,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "asdasdasd",
            "pandal_facebook_link": "asdasdasd",
            "pandal_insta_link": "",
            "pandal_pincode": "7008",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "0ilglol3",
            "createdAt": "2023-09-07T20:25:10.162Z",
            "updatedAt": "2023-09-07T20:25:10.162Z",
            "__v": 0
        },
        {
            "_id": "64fb28f040457d0f353521db",
            "name_of_pandal": "New Pandal Name",
            "address_of_pandal": "Some Real Address Here",
            "president_of_pandal": "Rony Roy",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Arko Dey",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "Paschim Medinipur",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Riju Dutta",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Smriti Dey",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "Theme name",
            "pandal_description": "A pandal celebrating durga puja.",
            "pandal_architech": "Architech name",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": true,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": true,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "asdasd",
            "echofriendly_pandal": "no",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": true,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": true,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": false,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "asdasd",
            "pandal_facebook_link": "asdasdasd",
            "pandal_insta_link": "",
            "pandal_pincode": "7008",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "kdtc7fhtd",
            "createdAt": "2023-09-08T14:00:16.078Z",
            "updatedAt": "2023-09-08T14:00:16.078Z",
            "__v": 0
        },
        {
            "_id": "64fb3d3d40457d0f353521dd",
            "name_of_pandal": "New Pandal Name",
            "address_of_pandal": "Some Real Address Here",
            "president_of_pandal": "Rony Roy",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Arko Dey",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "Others",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Riju Dutta",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Smriti Dey",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "Theme name",
            "pandal_description": "A pandal celebrating durga puja.",
            "pandal_architech": "Architech name",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "asdasdasd",
            "echofriendly_pandal": "yes",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": true,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "asdasd",
            "pandal_facebook_link": "asdasd",
            "pandal_insta_link": "",
            "pandal_pincode": "7002",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "r12jgggngi",
            "createdAt": "2023-09-08T15:26:53.949Z",
            "updatedAt": "2023-09-08T15:26:53.949Z",
            "__v": 0
        },
        {
            "_id": "64fb3e962428f7623a673013",
            "name_of_pandal": "New Pandal Name",
            "address_of_pandal": "Some Real Address Here",
            "president_of_pandal": "Rony Roy",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Arko Dey",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "Paschim Bardhaman",
            "state_of_pandal": "west_bengal",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Riju Dutta",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Smriti Dey",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "Theme name",
            "pandal_description": "A pandal celebrating durga puja.",
            "pandal_architech": "Architech name",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "asdasd",
            "echofriendly_pandal": "yes",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": false,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": false,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "asdasd",
            "pandal_facebook_link": "asdasdasd",
            "pandal_insta_link": "",
            "pandal_pincode": "70008",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "prkj977js",
            "createdAt": "2023-09-08T15:32:38.924Z",
            "updatedAt": "2023-09-08T15:32:38.924Z",
            "__v": 0
        },
        {
            "_id": "64fb779440457d0f353521e0",
            "name_of_pandal": "New Pandal Name",
            "address_of_pandal": "Some Real Address Here",
            "president_of_pandal": "Rony Roy",
            "president_phoneno": "9239492289",
            "secretary_of_pandal": "Arko Dey",
            "secretary_phoneno": "9239492289",
            "landmark_of_pandal": "Near Mars Park",
            "district_of_pandal": "Bankura",
            "sociallink_of_pandal": "https://example.com/pandal-social",
            "contact_one_name": "Riju Dutta",
            "contact_one_phone": "555-123-4567",
            "contact_one_email": "riju@example.com",
            "contact_two_name": "Smriti Dey",
            "contact_two_phone": "555-987-6543",
            "contact_two_email": "dey@example.com",
            "pandal_theme": "Theme name",
            "pandal_description": "A pandal celebrating durga puja.",
            "pandal_architech": "Architech name",
            "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
            "pandal_materials": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "BAMBOO"
                },
                {
                    "id": 2,
                    "checked": false,
                    "name": "CLOTH"
                },
                {
                    "id": 3,
                    "checked": false,
                    "name": "WOOD"
                },
                {
                    "id": 4,
                    "checked": true,
                    "name": "IRON"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "PLY-WOOD"
                }
            ],
            "pandal_materials_others": "sdads",
            "echofriendly_pandal": "no",
            "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
            "pandal_amenities": [
                {
                    "id": 1,
                    "checked": false,
                    "name": "Toilet"
                },
                {
                    "id": 2,
                    "checked": true,
                    "name": "Entry / Exit"
                },
                {
                    "id": 3,
                    "checked": true,
                    "name": "VIP Entry"
                },
                {
                    "id": 4,
                    "checked": true,
                    "name": "Emergency"
                },
                {
                    "id": 5,
                    "checked": false,
                    "name": "First Aid"
                },
                {
                    "id": 6,
                    "checked": false,
                    "name": "Fire Extinguisher"
                },
                {
                    "id": 7,
                    "checked": false,
                    "name": "Drinking Water"
                }
            ],
            "pandal_amenities_others": "adsdad",
            "pandal_facebook_link": "",
            "pandal_insta_link": "sdad",
            "pandal_pincode": "77090",
            "pandal_files_link": "https://example.com/pandal-files",
            "pandalId": "c9t72c39j",
            "createdAt": "2023-09-08T19:35:48.841Z",
            "updatedAt": "2023-09-08T19:35:48.841Z",
            "__v": 0
        }
    ];
    const stateRef = useRef();
    const cityRef = useRef();
    const secretKeyRef = useRef();
    const mystyle = {
        // color: "white",
        // backgroundColor: "DodgerBlue",
        // padding: "10px",
        // fontFamily: "Arial"
        display:"inline"
    };
    const centerSt ={
        textAlign: "center"
    }
    const idToCheck = "KOSPQR";
    const ids = ["456UIP", "KOSPQR", "ABC123", "XYZ789"];   
      useEffect(()=>{
        // getAllData();
        setDistricts( [
            // { "id": 1, "name": "TRIPURA" },
            { "id": 2, "name": "Alipurduar" },
            { "id": 3, "name": "Bankura" },
            { "id": 4, "name": "Birbhum" },
            { "id": 5, "name": "Cooch Behar" },
            { "id": 6, "name": "Dakshin Dinajpur" },
            { "id": 7, "name": "Darjeeling" },
            { "id": 8, "name": "Hooghly" },
            { "id": 9, "name": "Howrah" },
            { "id": 10, "name": "Jalpaiguri" },
            { "id": 11, "name": "Jhargram" },
            { "id": 12, "name": "Kalimpong" },
            { "id": 13, "name": "Kolkata" },
            { "id": 14, "name": "Malda" },
            { "id": 15, "name": "Murshidabad" },
            { "id": 16, "name": "Nadia" },
            { "id": 17, "name": "North 24 Parganas" },
            { "id": 18, "name": "Paschim Bardhaman" },
            { "id": 19, "name": "Paschim Medinipur" },
            { "id": 20, "name": "Purba Bardhaman" },
            { "id": 21, "name": "Purba Medinipur" },
            { "id": 22, "name": "Purulia" },
            { "id": 23, "name": "South 24 Parganas" },
            { "id": 24, "name": "Uttar Dinajpur" }
          ]);
    },[]);
    // console.log(pandalData);
    const getAllData =async ()=>{
        setPandaData(data);
        // console.log(pandalData);
        // axios.get(enviromentConfigs?.REACT_APP_API_URL+'pandals/get')
        //     .then(response => {
        //         // Handle successful response
        //         console.log(response?.data?.data);
        //         setPandaData(response?.data?.data);
        //         console.log(pandalData);
        //         // history.push('/successpage', response?.data?.pandalId);
        //         // setShowSpinner(false);
                
        //     })
        //     .catch(error => {
        //         // Handle error
        //         console.log(error);
        //         // setShowSpinner(false);
        //     });
    };

    const handleChangeDis = (event)=>{
        const { name, value} = event.target;
        setState(event.target?.value);
        setCity("select");
        setClick(false);
        setPandaData([]);
        console.log(selectedState);
    };
    const handleChangeCity = (event)=>{
        const { name, value} = event.target;
        setCity(event.target?.value);
        setClick(false);
        setPandaData([]);
        console.log(selectedState);
    }
    const downloadData =()=>{
        setShowSpinner(true);
        if(selectedState !== 'select' && selectedCity !== 'select'){
            console.log('downloading....');
            console.log({selectedState:selectedState});
            console.log({selectedCity:selectedCity});
            let body ={
                selectedState:selectedState,
                selectedCity:selectedCity
            }
            axios.post(enviromentConfigs?.REACT_APP_API_URL+'pandals/get',body)
            .then(response => {
                // Handle successful response
                let data =response?.data?.data;
                console.log(data);
                setClick(true)
                // data = data?.map((e)=>{
                    
                //     console.log(JSON.stringify(e?.pandal_amenities));
                //     return JSON.stringify(e?.pandal_amenities)
                // })
                const filteredData = data.map((item) => ({
                    ...item,
                    pandal_amenities: item.pandal_amenities.filter((amenity) => amenity.checked === true).map((amenity) => amenity.name) //? IF ITS TRUE ONLY THEN TAKING NAME AND JOINING AS STRING
                    .join(', '),
                    pandal_materials: item.pandal_materials.filter((materials) => materials.checked === true).map((materials) => materials.name)
                    .join(', '),
                }));

                // const filteredData =[
                //     {
                //         "_id": "64fbbce6570ca4a9b9762e7f",
                //         "name_of_pandal": "Tripura Oanda",
                //         "address_of_pandal": "Some Real Address Here",
                //         "president_of_pandal": "Rony Roy",
                //         "president_phoneno": "9239492289",
                //         "secretary_of_pandal": "Arko Dey",
                //         "secretary_phoneno": "9239492289",
                //         "landmark_of_pandal": "Near Mars Park",
                //         "district_of_pandal": "others",
                //         "state_of_pandal": "tripura",
                //         "sociallink_of_pandal": "https://example.com/pandal-social",
                //         "contact_one_name": "Riju Dutta",
                //         "contact_one_phone": "555-123-4567",
                //         "contact_one_email": "riju@example.com",
                //         "contact_two_name": "Smriti Dey",
                //         "contact_two_phone": "555-987-6543",
                //         "contact_two_email": "dey@example.com",
                //         "pandal_theme": "Theme name",
                //         "pandal_description": "A pandal celebrating durga puja.",
                //         "pandal_architech": "Architech name",
                //         "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
                //         "pandal_materials": "",
                //         "pandal_materials_others": "asdasdsa",
                //         "echofriendly_pandal": "yes",
                //         "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
                //         "pandal_amenities": "Toilet, Emergency",
                //         "pandal_amenities_others": "asdasd",
                //         "pandal_facebook_link": "asdasd",
                //         "pandal_insta_link": "",
                //         "pandal_pincode": "90023",
                //         "pandal_files_link": "https://example.com/pandal-files",
                //         "pandalId": "18jr9oaqg6",
                //         "createdAt": "2023-09-09T00:31:34.479Z",
                //         "updatedAt": "2023-09-09T00:31:34.479Z",
                //         "__v": 0
                //     },
                //     {
                //         "_id": "64fbc8fc8e9d5ccefe9ce21b",
                //         "name_of_pandal": "Tripura 2",
                //         "address_of_pandal": "Some Real Address Here",
                //         "president_of_pandal": "Rony Roy",
                //         "president_phoneno": "9239492289",
                //         "secretary_of_pandal": "Arko Dey",
                //         "secretary_phoneno": "9239492289",
                //         "landmark_of_pandal": "Near Mars Park",
                //         "district_of_pandal": "others",
                //         "state_of_pandal": "tripura",
                //         "sociallink_of_pandal": "https://example.com/pandal-social",
                //         "contact_one_name": "Riju Dutta",
                //         "contact_one_phone": "555-123-4567",
                //         "contact_one_email": "riju@example.com",
                //         "contact_two_name": "Smriti Dey",
                //         "contact_two_phone": "555-987-6543",
                //         "contact_two_email": "dey@example.com",
                //         "pandal_theme": "Theme name",
                //         "pandal_description": "A pandal celebrating durga puja.",
                //         "pandal_architech": "Architech name",
                //         "pandal_why_unique": "Incorporating cutting-edge technology into the pandal design.",
                //         "pandal_materials": "BAMBOO, CLOTH",
                //         "pandal_materials_others": "hgjg",
                //         "echofriendly_pandal": "yes",
                //         "pandal_structure_description": "Futuristic and energy-efficient design inspired by comic book aesthetics.",
                //         "pandal_amenities": "VIP Entry, Emergency, Fire Extinguisher",
                //         "pandal_amenities_others": "kgkg",
                //         "pandal_facebook_link": "hgkkgkgj",
                //         "pandal_insta_link": "",
                //         "pandal_pincode": "800009",
                //         "pandal_files_link": "https://example.com/pandal-files",
                //         "pandalId": "6n44nqjn3",
                //         "createdAt": "2023-09-09T01:23:08.048Z",
                //         "updatedAt": "2023-09-09T01:23:08.048Z",
                //         "__v": 0
                //     }
                // ]

                 // const filteredData = data.map((item) => {
                //     const checkedAmenities = item.pandal_materials
                //       .filter((amenity) => amenity.checked === true)
                //       .map((amenity) => amenity.name)
                //       .join(', ');
                
                //     const amenitiesString = checkedAmenities || 'NA'; // Use 'NA' if all amenities are unchecked
                
                //     return {
                //       ...item,
                //       pandal_materials: amenitiesString,
                //     };
                //   });
                setPandaData(filteredData);
                console.log(filteredData);
                console.log(pandalData);
                // history.push('/successpage', response?.data?.pandalId);
                setShowSpinner(false);
                
            })
            .catch(error => {
                // Handle error
                console.log(error);
                setShowSpinner(false);
            });

        }else{
            alert('Please fill fields!')
        }
    }

    const doesIDMatchAny = (idToCheck, ids)=>{
        // Loop through the array of IDs
        for (const id of ids) {
          // Create a regular expression pattern to match the current ID exactly
          const idPattern = new RegExp(`^${id}$`);
      
          // Use the test method to check if idToCheck matches the current ID
          if (idPattern.test(idToCheck)) {
            return true; // If a match is found, return true
          }
        }
      
        return false; // If no match is found, return false
    };

    const verifyId =(idToCheck)=>{
        // e?.preventDefault();
        console.log(idToCheck);
        setUniqueID(doesIDMatchAny(idToCheck, ids)); 
    }
    console.log(uniqueID);
    console.log(pandalData);
  return (
    <>  {showSpinner?<Loader/>:null};
        {uniqueID?
        <div>
        <div class="UT7-body-area">
        <div class="container-fluid">  
        <div class="UT7-form-body-area">
            <h4 class="UT7-section-hdng">Data Download 
            {/* <button onClick={check}>Check</button> */}
            </h4>
            <div style={mystyle}>
            <select class="form-control" ref={stateRef} onChange={handleChangeDis}>
                <option value="select">Please Select State</option>
                <option value="west_bengal">West Bengal</option>
                <option value="tripura">Tripura</option>
            </select>
            </div>
            <br/>
            <div style={mystyle}>
                {selectedState === 'west_bengal'?
                <select class="form-control" ref={cityRef} onChange={handleChangeCity}>
                <option value="select">Please Select District</option>

                    {districts?.map(element => {
                return (
                <>
                <option value={element?.name}>
                {element?.name}
                {/* <b>{element?.name}</b> */}
                </option>
                </>
                );
            })}
                </select>
            :null} 
            
            {selectedState === 'tripura'?
                <select class="form-control" ref={cityRef} onChange={handleChangeCity}>
                <option value="select">Please Select District</option>
                <option value="others">Others</option>
                </select>
            :null} 
            </div>
            
            {selectedCity !== "select"?
            <button type="button" class="btn btn-primary btn-lg mt-4" onClick={downloadData}>Fetch Data</button>
            // <CSVLink data={pandalData} class="btn btn-primary btn-lg mt-4" filename={'Data_of_'+selectedState+'_'+selectedCity} onClick={downloadData}>Download Data</CSVLink>
            :null}
            {pandalData.length > 0 && clickedOnDownload? <CSVLink data={pandalData} class="btn btn-success btn-lg mt-4" filename={'Data_of_'+selectedState+'_'+selectedCity}>Download Data</CSVLink>:null}
            {pandalData.length == 0 && clickedOnDownload?<button type="button" class="btn btn-danger btn-lg mt-4">No Data To Download</button>:null}

        {/* <div id="toolbar">
        <select class="form-control">
                <option value="">Select State</option>
                <option value="all">Kolkata</option>
                <option value="selected">Tripura</option>
        </select>
        </div> */}
            {/* <table class="UT7-table">
                <tr>
                    <th>Name Of The Pandal</th>
                    <th>Col 2</th>
                    <th>Col 3</th>
                    <th>Col 4</th>
                    <th>Col 5</th>
                    <th>Action</th>
                </tr>
                { pandalData?.map((e)=>[
                <tr>
                    <td>{e?.name_of_pandal}</td>
                    <td>data 2</td>
                    <td>data 3</td>
                    <td>data 4</td>
                    <td>data 5</td>
                    <td>
                        <div class="UT7-table-btn-group">
                            <button type="button" class="btn btn-primary btn-sm">Primary</button>
                            <button type="button" class="btn btn-success btn-sm">Success</button>
                            <button type="button" class="btn btn-danger btn-sm">Danger</button>
                            <button type="button" class="btn btn-warning btn-sm">Warning</button>
                        </div>
                    </td>
                </tr>
                ])}
                <tr>
                    <td>data 1</td>
                    <td>data 2</td>
                    <td>data 3</td>
                    <td>data 4</td>
                    <td>data 5</td>
                    <td>
                        <div class="UT7-table-btn-group">
                            <button type="button" class="btn btn-primary btn-sm">Primary</button>
                            <button type="button" class="btn btn-success btn-sm">Success</button>
                            <button type="button" class="btn btn-danger btn-sm">Danger</button>
                            <button type="button" class="btn btn-warning btn-sm">Warning</button>
                        </div>
                    </td>
                </tr>
            </table> */}
        </div>

        </div>
        </div>
        
        {/* <div class="container">
<h1>Bootstrap Table</h1>

<p>A table with third party integration  extension Filter control extension Data export pour exporter</p>

<div id="toolbar">
		<select class="form-control">
				<option value="">Export Basic</option>
				<option value="all">Export All</option>
				<option value="selected">Export Selected</option>
		</select>
</div>

<table id="table" 
			 data-toggle="table"
			 data-search="true"
			//  data-filter-control="true" 
			 data-show-export="true"
			 data-click-to-select="true"
			 data-toolbar="#toolbar"
       class="table-responsive">
	<thead>
		<tr>
			<th data-field="state" data-checkbox="true"></th>
			<th data-field="prenom" data-filter-control="input" data-sortable="true">First Name</th>
			<th data-field="date" data-filter-control="select" data-sortable="true">Date</th>
			<th data-field="examen" data-filter-control="select" data-sortable="true">Examination</th>
			<th data-field="note" data-sortable="true">Note</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td class="bs-checkbox "><input data-index="0" name="btSelectItem" type="checkbox"/></td>
			<td>Jitender</td>
			<td>01/09/2015</td>
			<td>Français</td>
			<td>12/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="1" name="btSelectItem" type="checkbox"/></td>
			<td>Jahid</td>
			<td>05/09/2015</td>
			<td>Philosophie</td>
			<td>8/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="2" name="btSelectItem" type="checkbox"/></td>
			<td>Valentin</td>
			<td>05/09/2015</td>
			<td>Philosophie</td>
			<td>4/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="3" name="btSelectItem" type="checkbox"/></td>
			<td>Milton</td>
			<td>05/09/2015</td>
			<td>Philosophie</td>
			<td>10/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="4" name="btSelectItem" type="checkbox"/></td>
			<td>Gonesh</td>
			<td>01/09/2015</td>
			<td>Français</td>
			<td>14/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="5" name="btSelectItem" type="checkbox"/></td>
			<td>Valérie</td>
			<td>07/09/2015</td>
			<td>Mathématiques</td>
			<td>19/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="6" name="btSelectItem" type="checkbox"/></td>
			<td>Valentin</td>
			<td>01/09/2015</td>
			<td>Français</td>
			<td>11/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="7" name="btSelectItem" type="checkbox"/></td>
			<td>Eric</td>
			<td>01/10/2015</td>
			<td>Philosophie</td>
			<td>8/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="8" name="btSelectItem" type="checkbox"/></td>
			<td>Valentin</td>
			<td>07/09/2015</td>
			<td>Mathématiques</td>
			<td>14/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="9" name="btSelectItem" type="checkbox"/></td>
			<td>Valérie</td>
			<td>01/10/2015</td>
			<td>Philosophie</td>
			<td>12/20</td>
		</tr>
		<tr>
			<td class="bs-checkbox "><input data-index="10" name="btSelectItem" type="checkbox"/></td>
			<td>Eric</td>
			<td>07/09/2015</td>
			<td>Mathématiques</td>
			<td>14/20</td>
		</tr>
		<tr>
		<td class="bs-checkbox "><input data-index="11" name="btSelectItem" type="checkbox"/></td>
			<td>Valentin</td>
			<td>01/10/2015</td>
			<td>Philosophie</td>
			<td>10/20</td>
		</tr>
	</tbody>
</table>
        </div> */}
        </div>
        :
        <div class="UT7-body-area " style={centerSt} >
        <div class="container-fluid">  
        <div class="UT7-form-body-area" style={centerSt}>
        <div class="form-group">
        <form className="key-verify">
        <label for="secretKeyField" className="text-danger"><strong className="fw-bold">Please Enter Your Secret Key!</strong></label>
        <input type="text" class="form-control" id="secretKeyField" aria-describedby="emailHelp" placeholder="" ref={secretKeyRef}/>
        {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
        <button type = "button" className="btn btn-outline-danger mt-4 " onClick={()=>{
            console.log('sdsdadasd');
            verifyId(secretKeyRef?.current?.value)
        }}>Verify</button>
        </form>
        </div>
        </div>
        </div>
        </div>
    }
    </>
  )
}
