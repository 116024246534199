
//body
import homeLeftDecor from '../../assets/img/home-lft-decor.png';
import homeRightDecor from '../../assets/img/home-rgt-decor.png';
import durgaEye from '../../assets/img/durga-eye.png';
import bannerTag from '../../assets/img/banner-tag.png';
import chalChitra from '../../assets/img/chalchitra.svg';
import bannerTag2 from '../../assets/img/MOTHER CAMPAING_005.png';
import chalChitra2 from '../../assets/img/MOTHER CAMPAING_001.png';


import HeaderComponent from '../../components/header.component/header';
import FooterComponent from '../../components/footer.component/footer';
import { NavLink } from 'react-router-dom';
import "./home.css";


function HomePage(){

    return(
        <>
        {/* <!-- header start --> */}
        {/* <HeaderComponent/> */}
        {/* <!-- header ended --> */}
        
        {/* <!-- body start --> */}
        <div class="UT7-body-area home-page">
            <div class="container-fluid">
                <div class="UT7-home-wrapper">
                    <div class="UT7-home-lft-col">
                        <div class="UT7-home-yt-frame">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/UOW1-r2DrK0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                        <p>Celebrate the momentous occasion of Durga Puja's Intangible Cultural Heritage status bestowed by UNESCO, alongside the prestigious UltraTech 7 Wonders of Durga Puja, an esteemed recognition as India's First Engineers Choice Durga Puja Award. Join us in witnessing the breathtaking and awe-inspiring Wonders of Durga Puja with the support of UltraTech Cement. Prepare to be captivated by the sheer magnificence of this timeless festival, as we showcase the most intricate pandals and the most artistically crafted puja pandals.</p>
                    </div>
                    <div class="UT7-home-rgt-col">
                        <div class="UT7-chalchitra-full-wrap new">
                            {/* <img src={homeLeftDecor} class="UT7-chalchitra-side-decor left" /> */}
                            <div class="UT7-chalchitra">
                                <div class="UT7-chalchitra-content">
                                    {/* <img src={durgaEye} class="UT7-durga-eye" />
                                    <img src={bannerTag} class="UT7-banner-tag" /> */}
                                    <img src={bannerTag2} class="UT7-durga-eye" />
                                    <img src={chalChitra2} class="UT7-banner-tag" />
                                </div>
                                <NavLink to="/register"><button class="UT7-register-btn">REGISTER NOW</button></NavLink>
                                {/* <img src={chalChitra} class="UT7-chalchitra-design" /> */}
                            </div>
                            {/* <img src={homeRightDecor} class="UT7-chalchitra-side-decor right" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- body ended --> */}
        
        <div class="lft-bg"></div>
        <div class="rgt-bg"></div>
        </>
        )
}




export default HomePage;