import logo from "../../../assets/img/logo.png"
import arrowback from "../../../assets/img/arrow-back-outline-white.svg"
import downarrow from "../../../assets/img/down-arrow-icon.svg"
import menuIcon from "../../../assets/img/menu-icon.png"
import winnder1 from "../../../assets/img/Kolkata-Durga-Puja-2022/Kolkata-Durga-Puja-Winners-(1).jpg";
import winnder2 from "../../../assets/img/Kolkata-Durga-Puja-2022/Kolkata-Durga-Puja-Winners-(2).jpg";
import winnder3 from "../../../assets/img/Kolkata-Durga-Puja-2022/Kolkata-Durga-Puja-Winners-(3).jpg";
import winnder4 from "../../../assets/img/Kolkata-Durga-Puja-2022/Kolkata-Durga-Puja-Winners-(4).jpg";
import winnder5 from "../../../assets/img/Kolkata-Durga-Puja-2022/Kolkata-Durga-Puja-Winners-(5).jpg";
import winnder6 from "../../../assets/img/Kolkata-Durga-Puja-2022/Kolkata-Durga-Puja-Winners-(6).jpg";
import winnder7 from "../../../assets/img/Kolkata-Durga-Puja-2022/Kolkata-Durga-Puja-Winners-(7).jpg";
import { NavLink } from "react-router-dom";
import { PandalPopup } from "../../../components/pandalDetailsPopup/pandalPopup";
import zoomPic from "../../../assets/img/zoom-in-icon.svg"
import { useState } from "react";




export default function KDP2022KOL() {
    const [open,setIsOpen] = useState(false);
    const [pandal,setPandal] = useState();

    const openPopup = (title,desc,theme,img)=>{
        setIsOpen(true);
        setPandal({title:title,desc:desc,theme:theme,img});
    }
    
    const toggleFilterMenu=()=>{
        let menubtn = document.querySelector('.down');
        menubtn.classList.toggle("up");
        let parent = document.querySelector('.UT7-gallery-filter-list');
        parent.classList.toggle("mobileshow");
    }
  return (
    <>
    <div class="UT7-body-area">
    <div class="container-fluid">      

    <div class="UT7-gallery-body-section">

        <div class="UT7-gallery-filter-box">
            <div class="UT7-gallery-filter-hdng">
            <NavLink to="/gallery"><a class="back"><img src={arrowback} /></a> </NavLink>
                GALLERY & ARCHIVES
                <span class="down" onClick={toggleFilterMenu}><img src={downarrow}/></span>
            </div>
            <ul class="UT7-gallery-filter-list">
            <li class="back"> <NavLink to="/gallery"> <img src={arrowback} /><span>Back To Gallery</span> </NavLink> </li>
                <li > <NavLink to="/Bengal-Durga-Puja-2022"> <img src={menuIcon} /><span>Bengal Durga Puja 2022</span></NavLink> </li>
                <li class="active" ><a ><img src={menuIcon} /><span>Kolkata Durga Puja 2022</span></a></li>
                <li > <NavLink to="/Tripura-Durga-Puja-2022"> <img src={menuIcon} /><span>Tripura Durga Puja 2022</span> </NavLink> </li>
            </ul>
        </div>

        <div class="UT7-gallery-cards-area">
            <div class="row">

                <div class="col-xxl-12">
                    <h3 class="UT7-gallery-page-title">Kolkata Durga Puja 2022</h3>
                </div>
                
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder1} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('95 Pally Sarbojanin Durgotsab Committee','Portrays life as an infinite collection of moments from the past,the present and the future through the usage of materials like Cement,Jute, Paper,Fibre,Iron etc.', 'Trikal', winnder1);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="95 Pally Sarbojanin Durgotsab Committee" description="Portrays life as an infinite collection of moments from the past,the present and the future through the usage of materials like Cement,Jute, Paper,Fibre,Iron etc." theme="Trikal" img={winnder1} /> */}

                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder2} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Beliaghata 33No Palli Bashi Brinda',"Depicts the horrors of pandemic and it's effect on the common public through the usage of materials like Bricks,Pitch roads,Bamboo, Cement,Iron,Clay,etc", 'Chup kotha', winnder2);
                            openPopup('Dumdum Park Bharat Chakra Club',"Depicts the journey of life,death and new beginnings through the usage of materials such as Bamboo, Plywood, Cloth,Straw,Wood,etc.", 'Introspection', winnder2);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Beliaghata 33No Palli Bashi Brinda" description="Depicts the horrors of pandemic and it's effect on the common public through the usage of materials like Bricks,Pitch roads,Bamboo, Cement,Iron,Clay,etc" theme="Chup kotha" img={winnder2} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder3} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Chorebagan Sarbojanin Durgotsab Samity',"Depicts the power of our inner self through the usage of materials like Iron and Broken glass.", 'Antarshakti', winnder3);
                            openPopup('Vivekananda Park Atheletic Club',"Illustrates the concept of perspective through two dimensional and three dimensional designs using materials like Bamboo, Plywood, Iron,etc.", 'Different Point of View', winnder3);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Chorebagan Sarbojanin Durgotsab Samity" description="Depicts the power of our inner self through the usage of materials like Iron and Broken glass." theme="Antarshakti" img={winnder3} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder4} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Dumdum Park Bharat Chakra Club',"Depicts the journey of life,death and new beginnings through the usage of materials such as Bamboo, Plywood, Cloth,Straw,Wood,etc.", 'Introspection', winnder4);
                            openPopup('Hazra Park Durgotsab',"Showcase the essence of universal balance through the force of life through the usage of materials like Bamboo, Plywood, Tarpaulin, Fibre,etc.", 'Tandav', winnder4);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Dumdum Park Bharat Chakra Club " description="Depicts the journey of life,death and new beginnings through the usage of materials such as Bamboo, Plywood, Cloth,Straw,Wood,etc." theme="Introspection" img={winnder4} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder5} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Hazra Park Durgotsab',"Showcase the essence of universal balance through the force of life through the usage of materials like Bamboo, Plywood, Tarpaulin, Fibre,etc.", 'Tandav', winnder5);
                            openPopup('Beliaghata 33No Palli Bashi Brinda',"Depicts the horrors of pandemic and it's effect on the common public through the usage of materials like Bricks,Pitch roads,Bamboo, Cement,Iron,Clay,etc", 'Chup kotha', winnder5);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Hazra Park Durgotsab " description="Showcase the essence of universal balance through the force of life through the usage of materials like Bamboo, Plywood, Tarpaulin, Fibre,etc." theme="Tandav" img={winnder5} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder6} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Tala Prattoy',"Is a tribute to the power of human creativity through the use of materials like Bamboo,Sand,Cloth, Tarpaulin, Paper,Jute,etc.", 'Riti', winnder6);
                            openPopup('Chorebagan Sarbojanin Durgotsab Samity',"Depicts the power of our inner self through the usage of materials like Iron and Broken glass.", 'Antarshakti', winnder6);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Tala Prattoy " description="Is a tribute to the power of human creativity through the use of materials like Bamboo,Sand,Cloth, Tarpaulin, Paper,Jute,etc." theme="Riti" img={winnder6} /> */}
                    </div>
                </div>
                
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder7} class="UT7-card-thumb" />
                        <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('Tala Prattoy',"Is a tribute to the power of human creativity through the use of materials like Bamboo,Sand,Cloth, Tarpaulin, Paper,Jute,etc.", 'Riti', winnder7);
                        }}><img src={zoomPic} /></div>
                        {/* <PandalPopup title="Vivekananda Park Atheletic Club " description="Illustrates the concept of perspective through two dimensional and three dimensional designs using materials like Bamboo, Plywood, Iron,etc." theme="Different Point of View" img={winnder1} /> */}
                    </div>
                </div>

                <PandalPopup open={open} title={pandal?.title} description={pandal?.desc} theme={pandal?.theme} img={pandal?.img} close={()=>{ setIsOpen(false)}} />
            </div>
        </div>

    </div>

    </div>
    </div>
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>
  )
}
