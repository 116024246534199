import { useState } from "react";
import closeIcon from "../../assets/img/close-white-icon.svg"
import zoomPic from "../../assets/img/zoom-in-icon.svg"

function PandalPopup({open,title, description, theme, img, close}) {
    // const [isSet,setIsSet] = useState();

  
    console.log('popup setting');
    console.log(open);
    if(!open) return null
  return (
    <>
    {/* <div class="UT7-gallery-card-overlay" onClick={openPopup}><img src={zoomPic} /></div> */}
    <div class="UT7-gallery-card-popup show">
        <div class="UT7-gallery-card-popup-box">
            <span class="UT7-gallery-card-popup-close" onClick={close}><img src={closeIcon} /></span>
            <img src={img} class="UT7-gallery-card-popup-img" />
            <div class="UT7-gallery-card-popup-content">
                <h4 class="UT7-gallery-card-popup-hdng">{title}</h4>
                <h5 class="UT7-gallery-card-popup-theme-title">Theme: {theme}</h5>
                <p>{description}</p>
            </div>
        </div>
    </div>
    </>
  )
}


export {PandalPopup};