import './failed.css'
import { useNavigate } from 'react-router-dom';

export default function FaliedBox() {
    const navigate = useNavigate();
    const navigateToFrom = ()=>{
        navigate('/register')
    }

  return (
    <>
    <div class="UT7-body-area">
        <div class="container-fluid">      

            <div class="UT7-gallery-body-section failed">
                <div className="success-box justify-content-center">
                <div class="card">
                <div class="header">
                    {/* <div class="image">
                        <svg aria-hidden="true" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" fill="none">
                            <path d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" stroke-linejoin="round" stroke-linecap="round"></path>
                        </svg>
                    </div> */}
                    <svg class="crossmark animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="crossmark__circle animateElement" cx="26" cy="26" r="25" fill="none"/>
                    <path class="cross__path cross__path--right animateElement" fill="none" d="M16,16 l20,20" />
                    <path class="cross__path cross__path--left animateElement" fill="none" d="M16,36 l20,-20" />
                    </svg>
                    <div class="content">
                    <span class="title">Registration Unsuccessful</span>
                    {/* <p class="message">Your Unique ID is: <span className='alert alert-warning' >7839JSJ</span> </p> */}
                    </div>
                    <div class="actions">
                    <button class="cancel btn btn-success" type="button" onClick={navigateToFrom}>Please Try Again</button>
                    {/* <button class="cancel" type="button">Cancel</button> */}
                    </div>
                </div>
                </div>
                </div>
            </div>

        </div>
    </div>
                        
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>
     )
}
