import "./terms.css"

export default function TermsConditons() {
  return (
    <div class="UT7-body-area">
    <div class="container-fluid">      

    <div class="UT7-form-body-area">
        <div class="UT7-form-box fit_content">
            <h4 class="UT7-form-box-hdng">Terms & Conditions</h4>
            <div className="terms-con">
                    <ol>
                        <li>
                        UltraTech has the sole rights on 7 wonders of Durga Puja
                        </li>
                        <li>
                        Any fraudulent act related to registration in 7 Wonders should bring to the notice of campaign owner
                        </li>
                        <li>
                        Using the app unlawfully Harassing, threatening, abusing or intimidating other usersImpersonating someone else and using someone else's account
                        </li>
                        <li>
                        Spamming other users
            Reverse engineering the app
            Developing third party apps that interact with the app
                        </li>
                        <li>
                        Method required for canceling the registration in case of any fraudulent act - via email, written mail, online form, through a user account
                        </li>
                        <li>
                        Cancellation will take place - immediately and the registration data will be deleted  immediately. 
                        </li>
                        <li>
                        Ownership of content : ALl rights reserved with UltraTech 
                        </li>
                        <li>
                        The brand name / logo of UltraTech is for limited use of promotions only and the same doesn’t imply any endorsement by the company
                        </li>
                        <li>
                        Terms may be modified at any time
                        </li>
                        <li>
                        After modification, a user will be agreeing to new Terms if he continues using the app
                        </li>
                        <li>
                        A user should check the Terms periodically for these changes in the campaign 
                        </li>
                        <li>
                        Not liable for any damages that arise from the use of app.
                        </li>

                        <li>
                        Retention of Rights of Ownership/Ban on Infringement
                        </li>
                        <li>
                        Restriction on App Abuses
                        </li>
                        <li>
                        Disclaimer of Warranties and Liabilities
                        </li>
                        <li>
                        Retention of Right to Terminate/Ban Users
                        </li>
                        <li>
                        Campaign rights reserved with UltraTech 
                        </li>
                        <li>
                        This is the only App / Website to register in 7 Wonders
                        </li>
                        <li>
                        UltraTech is not responsible for any other APP /  Sites for registering in  7 Wonders
                        </li>
                    </ol>
            </div>
        </div>
    </div>

    </div>
    </div>
  )
}
