import { useEffect, useRef, useState } from 'react'
import './success.css'
import { useNavigate, useLocation  } from 'react-router-dom';

import Confetti from 'react-confetti';

export default function SuccessBox() {
    const [showConfetti,setShowConfetti] = useState(false);
    const [pandalUniqueID,setpandalUniqueID] = useState('')
    const navigate = useNavigate();
    const location = useLocation();
    const dimensions = {
        width: window.innerWidth,
        height: window.innerHeight
      };
    const {width,height} = dimensions;
    const navigateToHome = ()=>{
        navigate('/')
    }
    useEffect(()=>{
         setpandalUniqueID(localStorage.getItem('pandalUnique'));
        console.log(pandalUniqueID);
        setTimeout(() => {
            setShowConfetti(false);
        }, 10000);
    },[]);

  return (
    <>
    {showConfetti?<Confetti
      width={width}
      height={height}
    //   confettiSource={{
    //     w: 10,
    //     h: 10,
    //     x: width / 2,
    //     y: height / 2,
    //   }}
    numberOfPieces={1000}
    />:null}
    
    <div class="UT7-body-area">
        <div class="container-fluid">      

            <div class="UT7-gallery-body-section success">
                <div className="success-box justify-content-center">
                <div class="card">
                <div class="header">
                    {/* <div class="image">
                        <svg aria-hidden="true" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" fill="none">
                            <path d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" stroke-linejoin="round" stroke-linecap="round"></path>
                        </svg>
                    </div> */}
                    <div class="success-checkmark">
                    <div class="check-icon">
                        <span class="icon-line line-tip"></span>
                        <span class="icon-line line-long"></span>
                        <div class="icon-circle"></div>
                        <div class="icon-fix"></div>
                    </div>
                    </div>
                    <div class="content">
                    <span class="title">Registration Successful</span>
                    <p class="message">Your Unique ID is: <span className='alert alert-warning' >{pandalUniqueID}</span> </p>
                    </div>
                    <div class="actions">
                    <button class="cancel btn btn-success" type="button" onClick={navigateToHome}>Home</button>
                    {/* <button class="cancel" type="button">Cancel</button> */}
                    </div>
                </div>
                </div>
                </div>
            </div>

        </div>
    </div>
                        
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>
     )
}
