//footer
import { NavLink } from 'react-router-dom';
import footerImg from '../../assets/img/footer-img.png';
import "./footer.css"

function FooterComponent(){
    return(<>
     {/* <!-- header start --> */}
        <footer class="UT7-footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-4">
                        
                    </div>
                    <div class="col-4 ">
                        <div class="UT7-footer-box">
                            Copyright © 2010-2024
                            {/* <span className='madeBy'>Made by</span> */}
                        </div>
                        
                        
                    </div>
                    <div className='col-4'>
                    <div className="terms">
                        <NavLink to={'/terms-and-conditions'}>
                        <span className='noLink'>Terms & Conditions</span>
                        </NavLink>
                    </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <!-- header ended --> */}



        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    ...
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div>
                                </div>
                            </div>
                            </div>
        
    </>)
}









export default FooterComponent;