import './loader.css';
import ReactDom from 'react-dom';

 function Loader() {
  return ReactDom.createPortal(
    <>
    <div className='loader'>
        {/* Loader */}
    <div class="🤚">
    <div class="👉"></div>
    <div class="👉"></div>
    <div class="👉"></div>
    <div class="👉"></div>
    <div class="🌴"></div>		
    <div class="👍"></div>
    </div>
    </div>
    <div className='loaderOverlay'>
    </div>
    </>,
    document.getElementById('bgloader')
  )
}
export default Loader;