import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, createRoutesFromElements, Link, NavLink, Route, RouterProvider, Routes } from 'react-router-dom';

//pages
import UnderDev from './pages/underdev';
import HomePage from './pages/home/home.page';
import HeaderComponent from './components/header.component/header';
import GalleryPage from './pages/gallery/gallery.page';

//KDP
import KDP2020 from './pages/temp/KDP2020/KDP2020';


// layout
import RootLayout from './layouts/RootLayout';
import GalleryLayout from './layouts/galleryLayout';
import KDP2021 from './pages/temp/KDP2021/KDP2021';
import KDP2022 from './pages/temp/KDP2022/KDP2022';
import KDP2022KOL from './pages/temp/KDP2022/KDP2022_kol';
import KDP2022TRIPURA from './pages/temp/KDP2022/KDP2022_tripura';
import {FormPage, ShowSelection} from './pages/form/form';
import SuccessBox from './components/success-section/success';
import FaliedBox from './components/failure-section/failed';
import AboutPage from './pages/about/about.page';
import TermsConditons from './pages/terms/TermsConditons';
import Table from './pages/datadownload/Table';
import KDP2023KOLKATA from './pages/temp/KDP2023/KDP2023';
import ROB2023 from './pages/temp/KDP2023/ROB2023';
import { LoginPage } from './pages/auth/login/login';
import SeeHistory from './pages/see-history/see-history';

const mRouter = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<RootLayout/>}>
        {/* <Route index element={<HomePage/>}/> */}
        <Route path="/" element={<HomePage/>}/>
        <Route path="about" element={<AboutPage/>}/>
        <Route path="gallery" element={<GalleryPage/>}/>
        <Route path="Kolkata-Durga-Puja-2020" element={<KDP2020/>}/>
        <Route path="Kolkata-Durga-Puja-2021" element={<KDP2021/>}/>
        <Route path="Bengal-Durga-Puja-2022" element={<KDP2022/>}/>
        <Route path="Kolkata-Durga-Puja-2022" element={<KDP2022KOL/>}/>
        <Route path="Tripura-Durga-Puja-2022" element={<KDP2022TRIPURA/>}/>
        <Route path="Kolkata-Durga-Puja-2023" element={<KDP2023KOLKATA/>}/>
        <Route path="Bengal-Durga-Puja-2023" element={<ROB2023/>}/>
        <Route path="succespage" element={<SuccessBox/>}/>
        <Route path="failedpage" element={<FaliedBox/>}/>
        {/* <Route path="gallery" element={<GalleryLayout/>}>
          <Route path="years" element={<GalleryPage/>}/>
          <Route path="Kolkata-Durga-Puja-2020" element={<UnderDev/>}/>
        </Route> */}
        <Route path="register" element={<ShowSelection/>}/>
        <Route path="new-registration" element={<FormPage/>}/>
        <Route path="terms-and-conditions" element={<TermsConditons/>}/>
        <Route path="admin-data" element={<Table/>}/>
        <Route path="see-history" element={<SeeHistory/>}/>
        {/* //Login */}
        {/* <Route path="login" element={<LoginPage/>}/> */}
        <Route path="login" element={<ShowSelection/>}/>
        <Route path="*" element={<ShowSelection/>}/>
      </Route>
      
    )
)

function App() {
  return (
    <>
    {/* <div className='App'> */}
      {/* <UnderDev/> */}
      
      {/* <HomePage/> */}
    {/* </div> */}
    {/* <HeaderComponent/> */}
    <RouterProvider router={mRouter} />
    </>
  )
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
