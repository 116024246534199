import { NavLink, Route, Routes } from 'react-router-dom';

import mainLogo from '../../assets/img/logo.png';
import homeMenuIcon from '../../assets/img/home-menu-icon.svg';
import aboutMenuIcon from '../../assets/img/about-icon.svg';
import galleryMenuIcon from '../../assets/img/gallery-icon.svg';
import userMenuIcon from '../../assets/img/user-icon.svg';
import HomePage from '../../pages/home/home.page';



function HeaderComponent(){
    return(<>
     {/* <!-- header start --> */}
     <header class="UT7-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 UT7-hdr-full-col">
        
                    <a class="UT7-hdr-logo-link"><img src={mainLogo} alt="ultratech7wornders" title="ultratech7wornders" /></a>
        
                    <ul class="UT7-hdr-nav">
                        <li> <NavLink to="/"><img src={homeMenuIcon}/>Home</NavLink> </li>
                        <li><NavLink to="about"><img src={aboutMenuIcon}/>About Us</NavLink></li>
                        <li class="menu-logo"><img src={mainLogo} alt="ultratech7wornders" title="ultratech7wornders" /></li>
                        <li><NavLink to='gallery'><img src={galleryMenuIcon}/>Gallery</NavLink></li>
                        <li><NavLink to='register'><img src={userMenuIcon}/>Register</NavLink></li>
                    </ul>
        
                </div>
            </div>
        </div>
        </header>
        {/* <!-- header ended --> */}
    </>)
}









export default HeaderComponent;