import logo from "../../../assets/img/logo.png"
import arrowback from "../../../assets/img/arrow-back-outline-white.svg"
import downarrow from "../../../assets/img/down-arrow-icon.svg"
import menuIcon from "../../../assets/img/menu-icon.png"
import winnder1 from "../../../assets/img/Bengal-Durga-Puja-2023/ROB_JPG_001.jpg";
import winnder2 from "../../../assets/img/Bengal-Durga-Puja-2023/ROB_JPG_002.jpg";
import winnder3 from "../../../assets/img/Bengal-Durga-Puja-2023/ROB_JPG_003.jpg";
import winnder4 from "../../../assets/img/Bengal-Durga-Puja-2023/ROB_JPG_004.jpg";
import winnder5 from "../../../assets/img/Bengal-Durga-Puja-2023/ROB_JPG_005.jpg";
import winnder6 from "../../../assets/img/Bengal-Durga-Puja-2023/ROB_JPG_006.jpg";
import { NavLink } from "react-router-dom";
import winnder7 from "../../../assets/img/Bengal-Durga-Puja-2023/ROB_JPG_007.jpg";

import zoomPic from "../../../assets/img/zoom-in-icon.svg"
import closeIcon from "../../../assets/img/close-white-icon.svg"
import { PandalPopup } from "../../../components/pandalDetailsPopup/pandalPopup";
import { useState } from "react";


export default function ROB2023() {
    const [open,setIsOpen] = useState(false);
    const [pandal,setPandal] = useState();

    const openPopup = (title,desc,theme,img)=>{
        setIsOpen(true);
        setPandal({title:title,desc:desc,theme:theme,img});
    }
    

    const toggleFilterMenu=()=>{
        let menubtn = document.querySelector('.down');
        menubtn.classList.toggle("up");
        let parent = document.querySelector('.UT7-gallery-filter-list');
        parent.classList.toggle("mobileshow");
    }
  return (
    <>
    <div class="UT7-body-area">
    <div class="container-fluid">      

    <div class="UT7-gallery-body-section">

        <div class="UT7-gallery-filter-box">
            <div class="UT7-gallery-filter-hdng">
            <NavLink to="/gallery"><a class="back"><img src={arrowback} /></a> </NavLink>
                GALLERY & ARCHIVES
                <span class="down" onClick={toggleFilterMenu}><img src={downarrow}/></span>
            </div>
            <ul class="UT7-gallery-filter-list">
            <li class="back"> <NavLink to="/gallery"> <img src={arrowback} /><span>Back To Gallery</span> </NavLink> </li>

                <li > <NavLink to="/Kolkata-Durga-Puja-2023"> <img src={menuIcon} /><span>Kolkata Durga Puja 2023</span> </NavLink> </li>
                <li class="active"> <NavLink to="/Bengal-Durga-Puja-2023"> <img src={menuIcon} /><span>Bengal Durga Puja 2023</span></NavLink> </li>
              
                {/* <li class="active" > <a><img src={menuIcon} /><span>Tripura Durga Puja 2022</span></a> </li> */}
            </ul>
        </div>

        <div class="UT7-gallery-cards-area">
            <div class="row">

                <div class="col-xxl-12">
                    <h3 class="UT7-gallery-page-title">Bengal Durga Puja 2023</h3>
                </div>
                
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder1} class="UT7-card-thumb" />
                        {/* <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            openPopup('SURUCHI SANGHA',"Our this year Puja Theme is basically represents the various positive aspects of not only the Supreme Mother but also of our own state West Bengal whom we also consider our mother With this perspective this year well be depicting the essence of Bengali culture craftsmanship and the innate nature of Bengali existentialism.", 'MAA TOR EKOI ONGE ATO RUP', winnder1);
                        }}><img src={zoomPic} /></div> */}
                        {/* <PandalPopup title="Aikatan Juba Sanstha" description="Commemorates Ma Durga's victory over COVID-19,who is portrayed as Mahishasur.The theme is conveyed through the spectacular lights and sound shows." theme="Asho Maa" img={winnder1} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder2} class="UT7-card-thumb" />
                        {/* <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Yuba Samaj',"Pays homage to the Bengali warriors and freedom fighters who helped bring about this glorious occasion of 75 years of independence.The theme is personified through the use of independence-themed lights and sound shows.", 'Azadi ka Amrit Mahotsav', winnder2);

                            openPopup('Mitali (Kankurgachi)',"Mitali Kankurgachi, a well-known Durga committee in North Kolkata, celebrates its 87th anniversary this year. Traditionally, we've showcased captivating and innovative themes, but this year, we're embracing a different approach. Our theme, \"Icche Architect\" by Prasanta Paul, narrates the story of a humble hawker dreaming of a grand Durga Puja at a banedi bari during sleepless nights. Our focus is on bringing this imaginative tale to life in our mandap.", 'ICCHE', winnder2);
                        }}><img src={zoomPic} /></div> */}
                        {/* <PandalPopup title="Yuba Samaj" description="Pays homage to the Bengali warriors and freedom fighters who helped bring about this glorious occasion of 75 years of independence.The theme is personified through the use of independence-themed lights and sound shows." theme="Azadi ka Amrit Mahotsav" img={winnder2} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder3} class="UT7-card-thumb" />
                        {/* <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Ramthakur Sangha',"Is a replica of Lord Shiva's 2000-years-old Mandir,which is thought to be the first of the 12 jyotir linga shrines.", 'Gujarat Somnath Mandir', winnder3);
                            openPopup('CHOREBAGAN SARBOJANIN DURGOTSAB SAMITY',"Sri Debasis Barui's \"Anubhuti\" theme explores profound feelings based on individual perceptions of reality, transcending the material world. Anubhuti can manifest in various ways, like the emotions experienced when conceiving a child. These emotions are unique, defying easy description. Chorebagan Sarbojonin aims to convey this extraordinary experience through wood, iron, rust, shine, sanctity, and discordance, offering a one-of-a-kind experience to Maa Durga devotees and art enthusiasts.", 'SOMOYER TALE TALE BODLAY ANUBHUTI', winnder3);
                        }}><img src={zoomPic} /></div> */}
                        {/* <PandalPopup title="Ramthakur Sangha" description="Is a replica of Lord Shiva's 2000-years-old Mandir,which is thought to be the first of the 12 jyotir linga shrines." theme="Gujarat Somnath Mandir" img={winnder3} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder4} class="UT7-card-thumb" />
                        {/* <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Sanghati Club',"Promotes a pollution free environment for the benefit of humanity and future generations.", 'Shopno Uran', winnder4);
                            openPopup('BEHALA BUROSHIBTALA JANAKALYAN SANGHA',"Khokha a theme about the small boy who experiences Puja with his father who is a dhaki and waits for the puja to back to his home and meet his own mother.", 'KHOKHA', winnder4);
                        }}><img src={zoomPic} /></div> */}
                        {/* <PandalPopup title="Sanghati Club" description="Promotes a pollution free environment for the benefit of humanity and future generations." theme="Shopno Uran" img={winnder4} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder5} class="UT7-card-thumb" />
                        {/* <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Azah Hind Sangha',"Highlights the culture,food and life of jarawas tribe of Andaman.The theme represented by the use of over 30 materials gathered from the jungle where the tribes resides.", 'Amal Hazra', winnder5);
                            openPopup('ARJUNPUR AMRA SABAI CLUB',"For the 74th Durgotsav of Arjunpur Adhibasi Brinda, we're showcasing Bengal's ancient Babu culture through Kalighat pot paintings. These paintings from 1800 to 1850 AD capture the decadent Babu culture in Calcutta, reflecting social change, satirizing injustices, and promoting reform. The Kalighat artists entertained and depicted the colorful, satirical world of Babu culture in daily life.", 'GONODEBOTA', winnder5);
                        }}><img src={zoomPic} /></div> */}
                        {/* <PandalPopup title="Azah Hind Sangha" description="Highlights the culture,food and life of jarawas tribe of Andaman.The theme represented by the use of over 30 materials gathered from the jungle where the tribes resides." theme="Amal Hazra" img={winnder5} /> */}
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder6} class="UT7-card-thumb" />
                        {/* <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Netaji Play Forum Center',"Is a replica of largest temple in India built in the remembrance of Lord Ram with a twist.", 'Ayodhya Ram Mandir', winnder6);
                            openPopup('66 Pally Club',"Maa Durga, daughter of Raja Daksha and the better half of Lord Shiva (Maa Parvati), transcends boundaries. She permeates every level of society, from homes to the crematorium, and embodies limitless power. Crafted from diverse clays, she symbolizes unity. 66 Palli Club narrates the story of Durga and Parvati, revealing the truth of Maati Maatir Durga, representing the essence of society.", 'Durgar Maati, Maatir Durga', winnder6);
                        }}><img src={zoomPic} /></div> */}
                        {/* <PandalPopup title="Netaji Play Forum Center" description="Is a replica of largest temple in India built in the remembrance of Lord Ram with a twist." theme="Ayodhya Ram Mandir" img={winnder6} /> */}
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder7} class="UT7-card-thumb" />
                        {/* <div class="UT7-gallery-card-overlay" 
                        onClick={()=>{
                            // openPopup('Nabadiganta',"Depicts the richness of handcrafted materials by utilising locally sourced Bamboo, Clothing and crafted materials among other things.The theme is also represented by the use of sound and light shows,in which the spiritual Omkar echoed to create a peaceful atmosphere.", 'Kalpaloke', winnder7);
                            openPopup('TALA PRATTOY',"The fiftieth presentation in the courtyard of Durga Puja art Kahan is a living field of my long 25 years of Puja art practice philosophy This is the story of an eternal relationship between me Durga.", 'KAHON', winnder7);
                        }}><img src={zoomPic} /></div> */}
                        {/* <PandalPopup title="Nabadiganta " description="Depicts the richness of handcrafted materials by utilising locally sourced Bamboo, Clothing and crafted materials among other things.The theme is also represented by the use of sound and light shows,in which the spiritual Omkar echoed to create a peaceful atmosphere" theme="Kalpaloke" img={winnder7} /> */}
                    </div>
                </div>
                <PandalPopup open={open} title={pandal?.title} description={pandal?.desc} theme={pandal?.theme} img={pandal?.img} close={()=>{ setIsOpen(false)}} />

            </div>
        </div>

    </div>

    </div>
    </div>
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>
  )
}
