import logo from "../../../assets/img/logo.png"
import arrowback from "../../../assets/img/arrow-back-outline-white.svg"
import downarrow from "../../../assets/img/down-arrow-icon.svg"
import menuIcon from "../../../assets/img/menu-icon.png"
import winnder1 from "../../../assets/img/Kolkata-Durga-Puja-2020/Kolkata-Durga-Puja-Winners-(1).jpg";
import winnder2 from "../../../assets/img/Kolkata-Durga-Puja-2020/Kolkata-Durga-Puja-Winners-(2).jpg";
import winnder3 from "../../../assets/img/Kolkata-Durga-Puja-2020/Kolkata-Durga-Puja-Winners-(3).jpg";
import winnder4 from "../../../assets/img/Kolkata-Durga-Puja-2020/Kolkata-Durga-Puja-Winners-(4).jpg";
import winnder5 from "../../../assets/img/Kolkata-Durga-Puja-2020/Kolkata-Durga-Puja-Winners-(5).jpg";
import winnder6 from "../../../assets/img/Kolkata-Durga-Puja-2020/Kolkata-Durga-Puja-Winners-(6).jpg";
import winnder7 from "../../../assets/img/Kolkata-Durga-Puja-2020/Kolkata-Durga-Puja-Winners-(7).jpg";
import { NavLink } from "react-router-dom";






export default function KDP2020() {
     const toggleFilterMenu=()=>{
        let menubtn = document.querySelector('.down');
        menubtn.classList.toggle("up");
        let parent = document.querySelector('.UT7-gallery-filter-list');
        parent.classList.toggle("mobileshow");
    }
  return (
    <>
    <div class="UT7-body-area">
    <div class="container-fluid">      

    <div class="UT7-gallery-body-section">

        <div class="UT7-gallery-filter-box">
            <div class="UT7-gallery-filter-hdng">
            <NavLink to="/gallery"><a class="back"><img src={arrowback} /></a> </NavLink>
                GALLERY & ARCHIVES
                <span class="down" onClick={toggleFilterMenu}><img src={downarrow}/></span>
            </div>
            <ul class="UT7-gallery-filter-list">
            <li class="back"> <NavLink to="/gallery"> <img src={arrowback} /><span>Back To Gallery</span> </NavLink> </li>
                <li class="active"><a ><img src={menuIcon} /><span>Kolkata Durga Puja 2020</span></a></li>
            </ul>
        </div>

        <div class="UT7-gallery-cards-area">
            <div class="row">

                <div class="col-xxl-12">
                    <h3 class="UT7-gallery-page-title">Kolkata Durga Puja 2020</h3>
                </div>
                
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder1} class="UT7-card-thumb" />
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder2} class="UT7-card-thumb" />
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder3} class="UT7-card-thumb" />
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder4} class="UT7-card-thumb" />
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder5} class="UT7-card-thumb" />
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="UT7-gallery-card auto">
                        <img src={winnder6} class="UT7-card-thumb" />
                    </div>
                </div>
                
            </div>
        </div>

    </div>

    </div>
    </div>
    <div class="lft-bg"></div>
    <div class="rgt-bg"></div>
    </>
  )
}
